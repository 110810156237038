<template>
  <div class="directflightsonly_top directbottom flex-wrap align-items-center">
    <search-destination :isFrom="true" componentName="from-destination" @closed="closed"/>
    <search-destination componentName="to-destination" @closed="closed"/>
    <search-date-range-picker componentName="calendar" @closed="closed"/>
    <passenger-selection />
    <br/>
    <search-destination v-if="isMultiCity" :isMultiCity="true" :isFrom="true" componentName="multi-from-destination" @closed="closed"/>
    <search-destination  v-if="isMultiCity" :isMultiCity="true" componentName="multi-to-destination" @closed="closed"/>

    <div class="btnedit_area d-flex align-items-center">
      <button class="editbtn" @click="() => showSearchFilterPanel('search')"><i class="fa-solid fa-pen"></i></button>
      <button class="editbtn fixbtn" @click="() => showSearchFilterPanel('filter')"><i class="fa-solid fa-sliders"></i><span>{{ filterAppliedCount }}</span></button>
    </div>
    <button href="#" class="btn btn-outline-primary" :class="{'disabled': (!isSearchAvailable || isLoading)}" @click="startSearch">{{$t("sabre.buttons.update-search")}}</button>
    <div class="btn btn-primary release-fix" @click="() => releaseSearchPanel('search')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startSearch from '../startSearchMixin';
import filterCount from '../filterCountMixin';

export default {
  name: 'sabre-search-down-pane',
  mixins: [startSearch, filterCount],
  components: {
    searchDestination: () => import('../searchPanelAtom/selectDestinations/searchDestination'),
    searchDateRangePicker: () => import('@/sabre/searchPanel/dateRange/searchDateRangePicker'),
    passengerSelection: () => import('../searchPanelAtom/passenger/passengerSelection'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      filterConditions: 'GET_SABRE_FILTER_CONDITIONS',
    }),
  },
  methods: {
    showSearchFilterPanel(type) {
      if (type === 'search') {
        this.$root.$emit('show-fix-filter-panel', false);
      }
      this.$root.$emit(`show-fix-${type}-panel`, true);
    },
    releaseSearchPanel(type) {
      this.$root.$emit(`show-fix-${type}-panel`, false);
    },
    closed(type) {
      if (type === 'from-destination') {
        this.$children.find((ch) => ch.componentName === 'to-destination').openDropdown();
      }
      if (type === 'to-destination') {
        this.$children.find((ch) => ch.componentName === 'calendar').openDropdown();
      }
      if (type === 'multi-from-destination') {
        this.$children.find((ch) => ch.componentName === 'multi-to-destination').openDropdown();
      }
    },
  },
};

</script>
