import { mapGetters } from 'vuex';

const startSearch = {
  computed: {
    ...mapGetters({
      isMultiCity: 'GET_SABRE_IS_MULTI_CITY_STATE',
      direction: 'GET_SABRE_SELECTED_FLIGHT_DIRECTION',
      cabinPrefs: 'GET_SABRE_SELECTED_FLIGHT_DEPARTMENTS',
      destination: 'GET_SABRE_CHOSEN_DESTINATIONS',
      multiCityDest: 'GET_SABRE_CHOSEN_MULTI_DESTINATIONS',
      isDirectOnly: 'GET_SABRE_IS_CHECKED_DIRECT_FLIGHTS_ONLY',

      calendarTypeList: 'GET_SABRE_CALENDAR_TYPE_LIST',
      calendarType: 'GET_SABRE_CALENDAR_TYPE',
      exactDate: 'GET_SABRE_EXACT_DATE_RANGE',
      specDate: 'GET_SABRE_SPECIFIC_DATE_RANGE',
      fullMonth: 'GET_SABRE_FULL_MONTHS',

      nights: 'GET_SABRE_NIGHTS_FOR_SEARCH',
      weekend: 'GET_SABRE_WEEKEND_TARGETED_FOR_SEARCH',
      midweek: 'GET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH',

      adults: 'GET_SABRE_ADULTS_FOR_SEARCH',
      children: 'GET_SABRE_CHILDREN_FOR_SEARCH',
      babies: 'GET_SABRE_BABIES_FOR_SEARCH',
      seniors: 'GET_SABRE_SENIORS_FOR_SEARCH',
      isStaging: 'GET_STAGING_MODE',
    }),
    isSearchAvailable() {
      const { isMultiCity, destination, multiCityDest, calendarType, calendarTypeList, exactDate, specDate, fullMonth, adults } = this;

      const dateCondition1 = calendarType === calendarTypeList[0] && exactDate.length;
      const dateCondition2 = calendarType === calendarTypeList[1] && specDate.length;
      const dateCondition3 = calendarType === calendarTypeList[2] && fullMonth.length;

      const destCondition = (isMultiCity && destination.from.length && destination.to.length && multiCityDest.from.length && multiCityDest.to.length)
       || (!isMultiCity && destination.from.length && destination.to.length);

      return (dateCondition1 || dateCondition2 || dateCondition3) && destCondition && !!adults;
    },
    fromDests() {
      const { destination } = this;
      return destination.from || null;
    },
    toDests() {
      const { destination } = this;
      return destination.to || null;
    },
    fromDests1() {
      const { isMultiCity, multiCityDest } = this;
      return isMultiCity ? multiCityDest.from : null;
    },
    toDests1() {
      const { isMultiCity, multiCityDest } = this;
      return isMultiCity ? multiCityDest.to : null;
    },
    startDate() {
      const { calendar, calendarType, exactDate, specDate } = this;
      return calendar !== calendarType[2] ? exactDate[0] || specDate[0] : null;
    },
    endDate() {
      const { calendar, calendarType, exactDate, specDate } = this;
      return calendar !== calendarType[2] ? exactDate[1] || specDate[1] : null;
    },
  },
  methods: {
    startSearch() {
      const { adults, children, babies, seniors, direction,
        fromDests, toDests, fromDests1, toDests1, calendarType, startDate, endDate, nights, weekend, midweek, fullMonth,
        isDirectOnly, cabinPrefs } = this;

      if (this.isSearchAvailable) {
        const queryBody = {
          direction,
          isDirectOnly,
          cabinPrefs: cabinPrefs.join(','),
          fromDests: fromDests.join(','),
          toDests: toDests.join(','),
          calendar: calendarType,
          adults,
          children,
          babies,
          seniors,
          nights,
          weekend,
          midweek,
        };
        if (fromDests1) queryBody.fromDests1 = fromDests1.join(',');
        if (toDests1) queryBody.toDests1 = toDests1.join(',');
        if (startDate) queryBody.startDate = startDate;
        if (endDate) queryBody.endDate = endDate;
        if (fullMonth) queryBody.fullMonth = fullMonth.join(',');

        const queryString = new URLSearchParams(queryBody).toString();
        const stagingParam = this.isStaging ? '&staging=true' : '';

        this.$store.commit('SET_SABRE_FLIGHT_STORED_SEARCH_RESULT', null);
        this.$store.commit('SET_SABRE_FLIGHT_SEARCH_QUERY', queryString);
        const path = `/sabre/fo-search-result/?${queryString}${stagingParam}`;
        // this.$router.push(path, null, () => { this.$router.push(path); });
        this.$router.push(path);
      }
    },
  },
};

export default startSearch;
