import { mapGetters } from 'vuex';

const filterCount = {
  computed: {
    ...mapGetters({
      filterConditions: 'GET_SABRE_FILTER_CONDITIONS',
      searchResult: 'GET_SABRE_FLIGHT_SEARCH_RESULT',
    }),
  },
  watch: {
    filterConditions: {
      immediate: true,
      handler() {
        const { filterConditions, searchResult } = this;
        if (!searchResult?.itineraries?.length) return;

        this.filterAppliedCount = 0;
        if (filterConditions.stopsFilterState.length !== 3) this.filterAppliedCount += 1;
        if (filterConditions.durFilterValue1 !== filterConditions.maxDuration1 || filterConditions.durFilterValue2 !== filterConditions.maxDuration2) this.filterAppliedCount += 1;
        if (filterConditions.selectedAirline.length !== filterConditions.airlineList.length) this.filterAppliedCount += 1;
        if (filterConditions.selectedAirport.length !== filterConditions.airportList.length) this.filterAppliedCount += 1;
        if (!this.arraysEqual(filterConditions.departureTimeFilter, [0, 24 * 60]) || !this.arraysEqual(filterConditions.arrivalTimeFilter, [0, 24 * 60])) this.filterAppliedCount += 1;
        if (!this.arraysEqual(filterConditions.budgetDuration, [filterConditions.minBudget, filterConditions.maxBudget])) this.filterAppliedCount += 1;
      },
      deep: true,
    },
  },
  data() {
    return {
      filterAppliedCount: 0,
    };
  },
  methods: {
    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;

      const sortedArr1 = [...arr1].sort();
      const sortedArr2 = [...arr2].sort();

      for (let i = 0; i < sortedArr1.length; i += 1) {
        if (sortedArr1[i] !== sortedArr2[i]) return false;
      }

      return true;
    },
  },
};

export default filterCount;
